// ** Initial State
const initialState = {
  allDataCate: [],
  dataCate: [],
  totalCate: 1,
  paramsCate: {},
  selectedCategory: null
}

const categories = (state = initialState, {type,payload}) => {
  switch (type) {
    case (payload?.Type):
      return { ...state, ...payload }
    default:
      return { ...state }
  }
}
export default categories
